import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const LoadingPage = () => (
  <Layout>
    <SEO title="Day: The Dailyness App" />
    <div className="loadingImage" >
      <img alt="Loading" src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Fday-logo-loading.png?alt=media&token=736a927d-b372-4fc2-a8ef-6badf3be3c93" />
    </div>
  </Layout>
)

export default LoadingPage
